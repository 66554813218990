.galery {
  // display: flex;
  // align-items: center;
  // justify-content: center;
  // min-height: 90vh;
  // width: 100%;
  // height: 100%;
  // background: #5b0242;
  // padding: 20rem;
  // position: relative;
  // overflow: hidden;
  .image-container{
    height: 600px;
    width: 100vw;
    padding: 0 100px;
    .swiper {
      width: 100%;
      height: 100%;
      border-radius: 40px;
    }

    .swiper-slide {
      height: 100%;
      width: 620px !important;
      text-align: center;
      font-size: 18px;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .vector {
    position: absolute;
    z-index: 1;
  }

  .title {
    position: absolute;
    top: 10rem;
    font-size: 5.5rem;
    color: #ffffff;
  }

  .grid-container {
    margin: 7rem auto;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
    grid-template-rows: repeat(auto-fill, 1fr);
    gap: 3rem;
    z-index: 10;

    height: 900px;
    width: 900px;
  }
  .grid-item {
    height: 100%;
    width: 100%;
    background-size: cover !important;
    background-position: center;
    background-repeat: no-repeat;
    overflow: hidden;
    // transition: all 0.3s ease;

    &:hover {
      transform: scale(1.02);
    }
  }

  .grid-item:first-child {
    background: url("../assets/galery/img1.JPG");
  }

  .grid-item:nth-child(2) {
    background: url("../assets/galery/img2.JPG");
  }

  .grid-item:nth-child(3) {
    background: url("../assets/galery/img3.JPG");
  }

  .grid-item:nth-child(4) {
    background: url("../assets/galery/img4.JPG");
  }

  .grid-item:nth-child(5) {
    background: url("../assets/galery/img5.JPG");
  }

  .grid-item:nth-child(6) {
    background: url("../assets/galery/img6.JPG");
  }

  .grid-item:nth-child(7) {
    background: url("../assets/galery/img7.JPG");
  }

  .grid-item:nth-child(8) {
    background: url("../assets/galery/img8.JPG");
  }

  .grid-item:nth-child(9) {
    background: url("../assets/galery/nail9.jpeg");
  }

  .grid-item:nth-child(10) {
    background: url("../assets/galery/nail10.jpeg");
  }

  .grid-item:nth-child(11) {
    background: url("../assets/galery/nail11.jpeg");
  }

  .grid-item:nth-child(12) {
    background: url("../assets/galery/nail12.jpeg");
  }
  
  .grid-item:nth-child(13) {
    background: url("../assets/galery/nail13.jpeg");
  }

  .grid-item:nth-child(14) {
    background: url("../assets/galery/nail14.jpeg");
  }

  .grid-item:nth-child(15) {
    background: url("../assets/galery/nail15.jpeg");
  }

  .big {
    grid-column: span 2;
    grid-row: span 2;
  }
  .little1 {
    grid-row: span 2;
  }
  .little2 {
    grid-column: span 2;
  }
}
/* media */
@media screen and (max-width: 840px) {
  .grid-container {
    width: 740px;
    grid-template-columns: 1fr 1fr;
    margin: 0px;
  }
  .item5,
  .item6,
  .item7,
  .item8,
  .item9,
  .item10,
  .item11,
  .item12 {
    display: none;
  }
}

@media screen and (max-width: 760px) {
  .grid-container {
    width: 500px;
    height: 500px;
    grid-template-columns: 1fr 1fr;
    grid-row: repeat(5, 1fr);
    margin: 0px;
    gap: 10px;
  }
}

@media screen and (max-width: 570px) {
  .title {
    font-size: 3rem !important;
  }
  .grid-container {
    display: flex !important;
    flex-direction: column;

    .grid-item {
      width: 300px !important;
      height: 500px !important;
      background-size: cover;
      object-fit: cover;
    }
  }
}
