.expert {
  width: 100%;
  height: 106rem;
  min-height: 106rem;
  background: #f4e6f0c5;
  // z-index: -3;
  position: relative;
  overflow: hidden;

  .big-logo {
    position: absolute;
    width: 141.6rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 10%;
  }

  .frame {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
  }
  .keen-slider{
    height: 100%;
  }
  .container {
    display: flex;
    align-items: center;
    height: 100%;
    z-index: 300;
    pointer-events: visible;

    .left {
      flex: 1;

      .card {
        width: 62.6rem;
        height: 76.8rem;
        border: 3px solid #f4e6f0;
        box-shadow: 0px 0px 5px 4px rgba(0, 0, 0, 0.144);
        padding: 4.7rem 3.8rem;

        .card-header {
          width: 55rem;
          height: 45rem;
          position: relative;
          background: #3d012b;

          img {
            position: absolute;
            width: 44.5rem;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
          }
        }

        .card-content {
          text-align: center;

          h3 {
            font-weight: 600;
            font-size: 5rem;
            padding: 3rem 0;
            letter-spacing: 2px;
          }

          h4 {
            color: #9b9b9b;
            font-size: 3.5rem;
            margin-bottom: 4rem;
          }

          a {
            font-size: 400;
            font-weight: 400;
          }
        }
      }
    }
  }
  .view {
    display: inline-block;
    font-size: 1.5rem;
    font-weight: 600;
    text-transform: uppercase;
    margin: 3rem 0;
    padding: 1.5rem 2.5rem;
    border: 3px solid #8f0366;
    border-radius: 3.5rem;
    transition: all 0.5s ease;

    display: none;

    &:hover {
      background: #8f0366;
      color: #f4e6f0;
    }
  }

  .right {
    flex: 1;
    height: 30%;
    padding: 0 4rem;

    .content {
      padding: 0 4rem;
      height: 100%;
      display: flex;
      flex-direction: column;

      h1 {
        font-size: 5.5rem;
        margin-bottom: 3rem;
        letter-spacing: 6px;
      }

      p {
        font-size: 2.4rem;
        line-height: 1.4;
        color: #646464;
      }
    }
  }
}

@media screen and (max-width: 1215px) {
  .expert {
    .container {
      .left {
        .card {
          width: 52.6rem;
          height: 65.8rem;

          .card-header {
            width: 45rem;
            height: 35rem;

            img {
              position: absolute;
              width: 35.5rem;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
            }
          }

          .card-content {
            text-align: center;

            h3 {
              font-size: 4rem;
              padding: 2.5rem 0;
            }

            h4 {
              font-size: 3rem;
              margin-bottom: 3rem;
            }

            a {
              margin-top: 1rem;
              font-size: 2rem;
              font-weight: 400;
            }
          }
        }
      }

      .right {
        padding: 0 1rem;

        .content {
          h1 {
            font-size: 4rem;
            margin-bottom: 2.5rem;
            letter-spacing: 6px;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 910px) {
  .expert {
    height: auto;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      width: 90%;

      .left {
        .card {
          width: 52.6rem;
          height: 65.8rem;

          .card-header {
            width: 45rem;
            height: 35rem;

            img {
              position: absolute;
              width: 35.5rem;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
            }
          }

          .card-content {
            text-align: center;

            h3 {
              font-size: 4rem;
              padding: 2.5rem 0;
            }

            h4 {
              font-size: 3rem;
              margin-bottom: 3rem;
            }

            a {
              margin-top: 1rem;
              font-size: 2rem;
              font-weight: 400;
            }
          }
        }
      }

      .right {
        padding: 0 1rem;

        .content {
          h1 {
            font-size: 4rem;
            margin-bottom: 2.5rem;
            letter-spacing: 6px;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .expert {
    height: auto;
    padding: 2rem 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      width: 90%;
      flex-direction: column-reverse;

      .left {
        text-align: center;
        padding-bottom: 5rem;

        .card {
          width: 52.6rem;
          height: 65.8rem;

          .card-header {
            width: 45rem;
            height: 35rem;

            img {
              position: absolute;
              width: 35.5rem;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
            }
          }

          .card-content {
            text-align: center;

            h3 {
              font-size: 4rem;
              padding: 2.5rem 0;
            }

            h4 {
              font-size: 3rem;
              margin-bottom: 3rem;
            }

            a {
              margin-top: 1rem;
              font-size: 2rem;
              font-weight: 400;
            }
          }
        }
      }

      .right {
        text-align: center;
        padding: 6rem 1rem;

        .content {
          h1 {
            font-size: 4rem;
            margin-bottom: 2.5rem;
            letter-spacing: 6px;
          }

          p {
            font-size: 2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .expert {
    .view {
      display: block;
    }
    .container {
      width: 90%;
      flex-direction: column-reverse;

      .left {
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 5rem;
        width: 100%;

        .card {
          width: 48.6rem;
          height: 57.8rem;

          .card-header {
            width: 40rem;
            height: 30rem;

            img {
              position: absolute;
              width: 30.5rem;
              left: 50%;
              bottom: 0;
              transform: translateX(-50%);
            }
          }

          .card-content {
            text-align: center;

            h3 {
              font-size: 3rem;
              padding: 2.5rem 0;
            }

            h4 {
              font-size: 3rem;
              margin-bottom: 3rem;
            }

            a {
              margin-top: 1rem;
              font-size: 2rem;
              font-weight: 400;
            }
          }
        }
      }

      .right {
        text-align: center;
        padding: 6rem 1rem;
        width: 100%;

        .content {
          h1 {
            font-size: 3.2rem;
            margin-bottom: 2rem;
            letter-spacing: 4px;
          }

          p {
            font-size: 1.8rem;
          }
        }
      }
    }
  }
}
