[class^="number-slider"],
[class*=" number-slider"] {
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 200px;
}

.navigation-wrapper {
  position: relative;
}

.dots {
  display: flex;
  padding: 10px 0;
  justify-content: center;
  background: #3d012b;
}

.dot {
  border: none;
  width: 10px;
  height: 10px;
  background: #c5c5c5;
  border-radius: 50%;
  margin: 0 5px;
  padding: 5px;
  cursor: pointer;
}

.dot:focus {
  outline: none;
}

.dot.active {
  background: #96006a;
}

.arrow {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}

.carusel {
  width: 100%;
  height: 84.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #3d012b;
  position: relative;
  padding: 3rem;
  overflow: hidden;

  .shape-left {
    position: absolute;
    bottom: -40rem;
    left: 0;
  }
  .shape-right {
    position: absolute;
    bottom: 0;
    right: 0;
    bottom: -40rem;
  }

  .title {
    position: absolute;
    top: 3rem;
    font-size: 5.5rem;
    color: #fff;
    margin-top: 3rem;
  }

  .wrapper {
    padding: 2rem;
    width: 50%;
    cursor: grab;

    .slide {
      height: auto;
      flex-direction: column;
      font-size: 1.4rem;
      padding: 3rem;
      text-align: center;

      .signs {
        margin: 4rem 0;
        opacity: 0.5;
      }

      p {
        font-size: 1.5rem;
        line-height: 18px;
        font-weight: 500;
        margin: 2rem 0;
        line-height: 2rem;
        margin-bottom: 5rem;
      }

      .desc {
        margin: 2rem 0;

        img {
          margin: 2rem 0;
        }
        h4 {
          font-size: 2.5rem;
          margin: 1rem 0;
        }

        h5 {
          font-size: 2rem;
          font-weight: normal;
        }
      }
    }
  }
}

@media screen and (max-width: 1215px) {
  .carusel {
    .title {
      font-size: 4rem;
    }
    .wrapper {
      width: 80%;
    }
  }
}

@media screen and (max-width: 760px) {
  .carusel {
    .title {
      font-size: 3rem;
    }
    .wrapper {
      width: 100%;
    }
  }

  .arrow {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .carusel {
    .signs {
      img {
        width: 3rem;
      }
    }
  }
}
