.top-footer {
  padding: 6rem 0;
  padding-bottom: 0;
  display: flex;

  background: url("../assets/Footer/bg-frame.png");

  .container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    .item {
      width: 22%;
      margin: 0 2rem;
      display: flex;
      align-items: start;
      flex-direction: column;
      margin: 2rem;

      .loc {
        display: flex;
        align-items: center;
        margin-bottom: 1.5rem;

        img {
          margin-right: 0.5rem;
        }

        h4 {
          display: inline-block;
        }
      }

      h3 {
        font-size: 2.5rem;
        letter-spacing: 2px;
        margin-bottom: 2rem;
      }

      span {
        width: 10rem;
        height: 4px;
        background: #ffd8cb;
        margin-bottom: 3rem;
      }

      p {
        font-size: 1.5rem;
        line-height: 2rem;
        color: #9b9b9b;
      }

      h4 {
        font-weight: 500;

        p {
          font-style: italic;
        }
      }
    }
  }
}

.bottom-footer {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #8f0365;

  .container {
    display: flex;
    align-items: center;
    justify-content: space-around;

    .icons {
      display: flex;

      li {
        padding: 0 2rem;
        opacity: 0.5;
      }
    }

    p {
      font-size: 1.5rem;
      color: #fff;
    }
  }
}

@media screen and (max-width: 1400px) {
  .container {
    width: 100%;
    align-items: center;
    justify-content: space-between;

    .item {
      width: 20%;
      margin: 0 1.5rem;
      margin: 1rem;

      h3 {
        font-size: 2.5rem;
        letter-spacing: 2px;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.2rem;
        line-height: 1.5rem;
      }
    }
  }
}

@media screen and (max-width: 1020px) {
  .top-footer {
    .container {
      width: 100%;
      justify-content: space-between;

      .item {
        width: 18%;
        margin: 0 1.5rem;
        margin: 0.5rem;
        flex: 1 1;
      }
    }
  }

  .bottom-footer {
    .container {
      .logo-container {
        width: 10rem;
        img {
          width: 100%;
        }
      }

      .title {
        p {
          font-size: 1rem;
        }
      }

      .icons {
        li {
          a {
            img {
              width: 2rem;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 910px) {
  .container {
    width: 100%;
    justify-content: space-between;

    .item {
      width: 18%;
      margin: 0 1.5rem;
      margin: 0.5rem;
      flex: 1 1;

      h3 {
        font-size: 1rem;
        letter-spacing: 1px;
        margin-bottom: 1.5rem;
      }

      p {
        font-size: 1.1rem;
        line-height: 1.2rem;
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .top-footer {
    .container {
      width: 90%;
      justify-content: space-between;
      flex-direction: column;

      .item {
        width: 70%;

        h3 {
          font-size: 3rem;
          letter-spacing: 2px;
          margin-bottom: 2rem;
        }

        p {
          font-size: 2rem;
          line-height: 4rem;
        }
      }
    }
  }

  .bottom-footer {
    .icons {
      .hide {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .top-footer {
    .container {
      width: 100%;
      padding-right: 0 1.5rem;

      .item {
        width: 100%;
      }
    }
  }

  .bottom-footer {
    .logo-container {
      img {
        width: 5rem !important;
      }
    }

    .icons {
      li {
        display: none;
      }

      .twitter {
        display: block !important;
      }
    }
  }
}
