.gallery-container{
    background-color: rgba(143, 3, 101, 0.11);
    h2.heading{
        margin: 1rem 0;
    padding: 4rem 0px;
    font-size: 3.5rem;
    text-align: center;
    font-weight: 600;
    letter-spacing: 3px;
      }
      #gallery{
        -webkit-column-count:4;
        -moz-column-count:4;
        column-count:4;
        
        -webkit-column-gap:20px;
        -moz-column-gap:20px;
        column-gap:20px;
      }
      @media (max-width:1200px){
        #gallery{
        -webkit-column-count:3;
        -moz-column-count:3;
        column-count:3;
          
        -webkit-column-gap:20px;
        -moz-column-gap:20px;
        column-gap:20px;
      }
      }
      @media (max-width:800px){
        #gallery{
        -webkit-column-count:2;
        -moz-column-count:2;
        column-count:2;
          
        -webkit-column-gap:20px;
        -moz-column-gap:20px;
        column-gap:20px;
      }
      }
      @media (max-width:600px){
        #gallery{
        -webkit-column-count:1;
        -moz-column-count:1;
        column-count:1;
      }  
      }
      #gallery img,#gallery video {
        width:100%;
        height: 500px !important;
        margin: 4% auto;
        box-shadow:-3px 5px 15px #000;
        cursor: pointer;
        -webkit-transition: all 0.2s;
        transition: all 0.2s;
      }
      .modal-img,.model-vid{
        width:100%;
        height:auto;
      }
      .modal-body{
        padding:0px;
      }
}