.about {
  position: relative;
  width: 100%;
  min-height: 83.4rem;
  height: 83.4rem;
  background: #e4e4e44d;
  overflow: scroll;

  .girl {
    height: 60rem;
  }

  .girl-mobile {
    display: none;
  }

  .logo-opacity {
    position: absolute;
    width: 71.5rem;
    top: -10rem;
    left: -10rem;
    opacity: 10%;
  }

  img[alt="shape"] {
    position: absolute;
    width: 20rem;
    opacity: 30%;
  }

  .top-shape {
    top: 0;
    right: 0;
  }

  .bottom-shape {
    left: 0;
    bottom: 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    .left {
      flex: 1;
      width: 100%;
      margin-right: 1rem;
    }

    .right {
      flex: 1;
      margin-left: 1rem;
      width: 100%;
      height: 70%;
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      margin-bottom: 3rem;

      h2 {
        font-size: 5.5rem;
        font-weight: 400;
        display: flex;
        flex-direction: column;
        span {
          display: inline-block;
          width: 9.5rem;
          height: 2px;
          margin-bottom: 2rem;
          margin-right: 1rem;
          background: #8f0366;
        }
      }

      h3 {
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.4;
      }

      p {
        font-size: 2rem;
        color: #9b9b9b;
      }

      a {
        display: inline-block;
        font-size: 1.5rem;
        padding: 1.4rem 2.4rem;
        border: 3px solid #ffd8cb;
        width: 16rem;
        border-radius: 56px;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
        color: #000;
        transition: background 0.5s ease;
      }

      a:hover {
        background: #ffd8cb;
      }
    }
  }
}

@media screen and (max-width: 1215px) {
  .about {
    .container {
      width: 80% !important;
      .left {
        margin-right: 2rem;
        .girl {
          width: 50rem;
          height: auto;
        }
      }

      .right {
        margin-left: 2rem;

        h2 {
          font-size: 4.5rem;
        }

        h3 {
          font-size: 1.7rem;
        }

        P {
          font-size: 1.6rem;
        }
      }
    }
  }
}

@media screen and (max-width: 910px) {
  .about {
    .container {
      width: 90% !important;
      .left {
        .girl {
          width: 40rem;
          height: auto;
        }
      }

      .right {
        h2 {
          font-size: 3.5rem;
        }

        h3 {
          font-size: 1.6rem;
        }

        P {
          font-size: 1.5rem;
        }
      }
    }
  }
}

@media screen and (max-width: 760px) {
  .about {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .container {
      width: 70% !important;
      height: 100%;
      flex-direction: column-reverse;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        .girl {
          margin: 2rem 0;
        }
      }

      .right {
        a {
          margin: 2rem 0;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .about {
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo-opacity {
      display: none;
    }

    .top-shape {
      display: none;
    }

    .bottom-shape {
      display: none;
    }

    .girl {
      display: none;
    }

    .girl-mobile {
      display: block;
      width: 100%;
    }

    .container {
      width: 100% !important;
      height: 100%;
      flex-direction: column;
      padding: 1rem 2rem;

      .left {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 3rem 0;
      }

      .right {
        h2 {
          font-size: 4rem;
          margin: 2rem 0;
        }

        p {
          margin: 2rem 0;
        }

        a {
          margin: 2rem 0;
        }
      }
    }
  }
}
